.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  color: forestgreen;
}
.background-image::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('/Users/nishankbansal/WebstormProjects/nastechglobal/public/appback.jpg');
  background-size: cover;
  background-position: center;
  opacity: .5;
  z-index: -1;
}
.company-name {
  align-self: flex-start;
  align-content: start;
  align-items: flex-start;
  margin: 20px; /* This gives some space from the top left corner */
  font-size: 1.95rem; /* Adjust size as needed */
  position: relative;
  font-family:"Trebuchet MS";
  color: black;

}

.coming-soon {
  font-size: 3.25rem; /* Large size for 'Coming Soon' text */
  font-weight: bold; /* Makes 'Coming Soon' bold */
  position: relative;
  height: 100vh;
  text-align: center;
  align-self: center;
  align-content: center;
  align-items: center;
  color: black;
  font-family:"Trebuchet MS";

}